import { HttpClient, URL_API } from "../../network";

export type TLocation = {
  address: string;
  ampeco_location_id: number;
  city: string;
  country: string;
  createdAt: string;
  description: string;
  image: string;
  is_enabled: boolean;
  latitude: number;
  longitude: number;
  name: string;
  postal: string;
  region: string;
  status: string;
  updatedAt: string;
  _id: string;
};

class LocationServiceApi extends HttpClient {
  getListLocation = async (params: TParams & { charge_point_id?: string }) => {
    const data = await this.instance.get(URL_API.LOCATION.GET_LIST, {
      params,
    });
    return data;
  };

  getDetailLocation = async (idLocation: string) => {
    const { data } = await this.instance.get(
      URL_API.LOCATION.GET_DETAIL + `/${idLocation}`,
    );
    return data;
  };

  createLocation = async (dataUpdate: TLocation) => {
    const { data } = await this.instance.post(
      URL_API.LOCATION.UPDATE + `${dataUpdate._id}`,
      dataUpdate,
    );
    return data;
  };

  updateLocation = async (dataUpdate: TLocation) => {
    const { data } = await this.instance.put(
      URL_API.LOCATION.UPDATE + `${dataUpdate._id}`,
      dataUpdate,
    );
    return data;
  };

  deleteLocation = async (idLocation: string) => {
    const { data } = await this.instance.delete(
      URL_API.LOCATION.DELETE + `${idLocation}`,
    );
    return data;
  };

  enableSubscriptionPlans = async (dataCreate: any) => {
    const { data } = await this.instance.post(
      URL_API.LOCATION.ENABLE_SUBSCRIPTION_PLAN + `enable-subscription-plans`,
      dataCreate,
    );
    return data;
  };
}
export const LocationService = new LocationServiceApi();
