import { HttpClient, URL_API } from "../../network";

type TChargePoint = {};

class ChargePointServiceApi extends HttpClient {
  getListChargePoint = async (params: TParams & { location_id?: string }) => {
    const data = await this.instance.get<IResponse<Array<IChargePoint>>>(
      URL_API.CHARGE_POINTS.GET_LIST,
      {
        params,
      },
    );
    return data;
  };

  getDetailChargePoint = async (idChargePoint?: string) => {
    const { data } = await this.instance.get(
      URL_API.CHARGE_POINTS.GET_DETAIL + `/${idChargePoint}`,
    );
    return data;
  };

  updateChargePoint = async (dataUpdate: TChargePoint) => {
    const { data } = await this.instance.post(
      URL_API.CHARGE_POINTS.UPDATE,
      dataUpdate,
    );
    return data;
  };

  deleteChargePoint = async (idChargePoint: string) => {
    const { data } = await this.instance.post(
      URL_API.CHARGE_POINTS.UPDATE + `${idChargePoint}`,
    );
    return data;
  };

  getListRestrictedUser = async (params: TParams) => {
    const data = await this.instance.get(
      URL_API.CHARGE_POINTS.GET_LIST_RESTRICTED,
      {
        params,
      },
    );
    return data;
  };

  enableSubscriptionPlans = async (dataCreate: any) => {
    const { data } = await this.instance.post(
      URL_API.CHARGE_POINTS.ENABLE_SUBSCRIPTION_PLAN +
        `enable-subscription-plans`,
      dataCreate,
    );
    return data;
  };
}
export const ChargePointService = new ChargePointServiceApi();
