import {
  Box,
  Checkbox,
  TableCell,
  TableCellProps,
  TableRow,
  TextField,
} from "@mui/material";
import { memo, PropsWithChildren, useEffect } from "react";
import { useDebounce } from "../../hooks/useDebounce";

export const ColumnTitle = memo(
  (
    props: PropsWithChildren &
      TableCellProps & {
        filter?: boolean;
        onChangeText?: (arg: string) => void;
        disable?: boolean;
      },
  ) => {
    const { value, setValue } = useDebounce({ initialValue: "", delay: 300 });

    useEffect(() => {
      props?.onChangeText?.(value);
    }, [value]);

    return (
      <TableCell
        sx={{
          minWidth: 150,
        }}
        {...props}
      >
        <Box minWidth={"max-content"} {...props}>
          {props.children}
        </Box>
        <TextField
          size="small"
          disabled={props?.disable}
          placeholder="Type to Filter"
          autoComplete="off"
          fullWidth
          onChange={(e) => setValue(e.target.value)}
          sx={{ visibility: props.filter ? "unset" : "hidden" }}
        />
      </TableCell>
    );
  },
);

export const ColumnSelect = memo(
  (
    props: PropsWithChildren &
      TableCellProps & { selectAll: boolean; onCallback: (e: boolean) => void },
  ) => {
    return (
      <TableCell width={70} align="center" {...props}>
        <Box {...props}>{props.children}</Box>
        <Checkbox
          checked={props.selectAll}
          onChange={(e) => props.onCallback(e.target.checked)}
        />
      </TableCell>
    );
  },
);

export const NoDataTable = ({ numberOfCol }: { numberOfCol: number }) => {
  return (
    <TableRow>
      {Array.from({ length: numberOfCol }, (_, index) => {
        if (index === 0)
          return (
            <TableCell
              key={index}
              sx={{
                position: "sticky",
                left: 0,
              }}
            >
              No data table
            </TableCell>
          );
        return <TableCell key={index}></TableCell>;
      })}
    </TableRow>
  );
};
