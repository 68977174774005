import { useMutation, useQuery } from "react-query";
import { LocationService } from "./location.service";
import toast from "react-hot-toast";

// GET List
const useGetList = (page: number, charge_point_id?: string) => {
  return useQuery(
    ["listLocation", page],
    async () => {
      const { data } = await LocationService.getListLocation({
        page,
        charge_point_id: charge_point_id,
      });
      return data;
    },
    { keepPreviousData: true, retryOnMount: false },
  );
};

const useEnableSubscriptionPlans = (cb?: (data: any) => void) => {
  return useMutation(
    async (createItem: any) => {
      const data = await LocationService.enableSubscriptionPlans(createItem);
      return data;
    },
    {
      onSuccess: (data) => {
        cb?.(data);
        if (data.ok) {
          toast.success("Create successfully!");
        } else {
          toast.error(data?.msg);
        }
      },
      onError(error, variables, context) {
        console.log(error);
      },
    },
  );
};

export { useGetList, useEnableSubscriptionPlans };
