import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Grid,
  InputAdornment,
  styled,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Scrollbars from "react-custom-scrollbars-2";
import { ColumnTitle, IconApp, Modal } from "../";
import SaveIcon from "@mui/icons-material/Save";
import BoltIcon from "@mui/icons-material/Bolt";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";

export const ModalRestrictedUser = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      {...props}
      name="Restricted Users"
      description="View & edit your charge point’s pricing"
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Grid container bgcolor={"#1C1C1C"} borderRadius="12px" p={"18px 21px"}>
          <Grid item xs={3} borderRight="1px solid rgba(255, 255, 255, 0.12)">
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Model
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              PETAS - 130
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <Box display={"flex"} gap={2} alignItems="center">
              <IconApp name={"AcCharger"} width="25px" />
              <Typography
                sx={{
                  fontSize: { xs: "17px" },
                }}
              >
                22 kW
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              PETAS - 130
            </Typography>
          </Grid>
          <Grid item xs={3} pl={1}>
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Charge point ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              PETAS - 130
            </Typography>
          </Grid>
        </Grid>

        <TableContainer>
          <Scrollbars
            autoHeight
            autoHeightMin="100%"
            autoHeightMax="100%"
            style={{ width: "100%" }}
            autoHide
          >
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <ColumnTitle filter>SN</ColumnTitle>
                  <ColumnTitle filter>User</ColumnTitle>
                  <ColumnTitle filter>Registered mobile no</ColumnTitle>
                  <ColumnTitle filter>Status</ColumnTitle>
                  <ColumnTitle filter>Joined date</ColumnTitle>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>1</TableCell>
                  <TableCell>Wee Keong</TableCell>
                  <TableCell>91996800</TableCell>
                  <TableCell>
                    <Chip
                      color="error"
                      label="Disable"
                      style={{ width: "100%" }}
                    />
                  </TableCell>
                  <TableCell>Tue Mar 28 2023 15:52:19 </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Scrollbars>
        </TableContainer>

        <Box display={"flex"} flexDirection="column" gap="15px">
          <Box display={"flex"} gap={1} alignItems="center">
            <BoltIcon fontSize="medium" />
            <Typography fontSize={"14px"}>
              Ad restricted users{" "}
              <span style={{ color: "#9B9B9B" }}>
                Enter the user’s mobile numberin each line to add him/ her as a
                “RU”
              </span>
            </Typography>
          </Box>
          <Box>
            <Typography>Your mobile number</Typography>

            <TextField
              fullWidth
              placeholder="907134781"
              InputProps={{
                style: { paddingLeft: 0 },
                startAdornment: (
                  <InputAdornment position="start">
                    <Autocomplete
                      disablePortal
                      options={[]}
                      sx={{ width: 150 }}
                      renderInput={(params) => (
                        <TextField
                          style={{
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                          }}
                          {...params}
                        />
                      )}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box display={"flex"} gap={1} alignItems="center">
            <PersonAddAltIcon fontSize="medium" />
            <Typography fontSize={"14px"}>
              Add the user to ALL charger in the location
            </Typography>
            <Switch defaultChecked />
          </Box>

          <Box display={"flex"} gap={1} alignItems="center">
            <PriorityHighIcon fontSize="small" />
            <Typography fontSize={"10px"}>
              To make changes to electricity tariff, please contact your
              relationship offcier
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
            >
              Add more user
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth size="large" variant="text">
              Remove
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });
