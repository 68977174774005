import {
  Box,
  Button,
  styled,
  Grid,
  Typography,
  Switch,
  TextField,
  InputAdornment,
} from "@mui/material";
import { IconApp, Modal } from "../../";
import SaveIcon from "@mui/icons-material/Save";
import { TimePicker } from "@mui/x-date-pickers";

export const ModalIdlingPenalty = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      {...props}
      name="Idling penalty"
      description="View & edit your charge point’s pricing"
      icon={<IconApp width={"27px"} name={"IdLingPenalty"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={3}>
        <Grid container bgcolor={"#1C1C1C"} borderRadius="12px" p={"18px 21px"}>
          <Grid item xs={3} borderRight="1px solid rgba(255, 255, 255, 0.12)">
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Model
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              PETAS - 130
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <Box display={"flex"} gap={2} alignItems="center">
              <IconApp name={"AcCharger"} width="25px" />
              <Typography
                sx={{
                  fontSize: { xs: "17px" },
                }}
              >
                22 kW
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            borderRight="1px solid rgba(255, 255, 255, 0.12)"
            pl={1}
          >
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Location ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              PETAS - 130
            </Typography>
          </Grid>
          <Grid item xs={3} pl={1}>
            <Text
              sx={{
                fontSize: { xs: "13px" },
              }}
            >
              Charge point ID
            </Text>
            <Typography
              sx={{
                fontSize: { xs: "17px" },
              }}
            >
              PETAS - 130
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            justifyContent={"space-between"}
            alignItems="center"
          >
            <Box>
              <Typography fontSize={"14px"}>Idling penalty function</Typography>
              <Typography fontSize={"10px"} color="#9B9B9B">
                (Toggle to activate or deactive function)
              </Typography>
            </Box>
            <Switch defaultChecked />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Box>
              <Typography fontSize={"14px"}>Grace Period</Typography>
              <Typography fontSize={"10px"} color="#9B9B9B">
                (Time allowance for late-comers)
              </Typography>
            </Box>
            <Box display={"flex"} gap={1} mt={1}>
              <BoxValue active>
                <Typography fontSize={"20px"}>
                  15 <span>mins</span>
                </Typography>
              </BoxValue>
              <BoxValue active={false}>
                <Typography fontSize={"20px"}>
                  30 <span>mins</span>
                </Typography>
              </BoxValue>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            display="flex"
            flexDirection={"column"}
            justifyContent="space-between"
          >
            <Box display={"flex"} justifyContent="space-between">
              <Typography fontSize={"14px"}>
                Idling penalty fee (/min)
              </Typography>
              <Typography fontSize={"14px"}>0 S$</Typography>
            </Box>
            <TextField
              // size="small"
              fullWidth
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">S$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Box>
          <Typography fontSize={"14px"}>
            Customise your idling penalty period
          </Typography>
          <Typography fontSize={"10px"} color="#9B9B9B">
            (Set the timing you wish to impose idling penalty)
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <DayField day="Monday" />
          </Grid>
          <Grid item xs={6}>
            <DayField day="Tuesday" />
          </Grid>
          <Grid item xs={6}>
            <DayField day="Wednesday" />
          </Grid>
          <Grid item xs={6}>
            <DayField day="Thurday" />
          </Grid>
          <Grid item xs={6}>
            <DayField day="Friday" />
          </Grid>
          <Grid item xs={6}>
            <DayField day="Saturday" />
          </Grid>
          <Grid item xs={6}>
            <DayField day="Sunday" />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
            >
              Save Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth size="large" variant="text">
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

const Text = styled(Typography)({ color: "#9B9B9B" });

const DayField = ({ day }: { day: string }) => {
  return (
    <Box>
      <Box
        display={"flex"}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Typography fontSize={"14px"} mb={"10px"}>
          {day}
        </Typography>
        <Switch defaultChecked />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <TimePicker
            slots={{
              textField: (params) => (
                <TextField
                  autoComplete="off"
                  size="small"
                  {...params}
                  fullWidth
                />
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            slots={{
              textField: (params) => (
                <TextField
                  autoComplete="off"
                  size="small"
                  {...params}
                  fullWidth
                />
              ),
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const BoxValue = styled(Box)<{ active: boolean }>(({ active }) => ({
  width: "100%",
  border: `1px solid ${active ? "#FFFF00" : "#C7C7C7"}`,
  color: active ? "#FFFF00" : "unset",
  borderRadius: "4px",
  padding: "12px 20px",
  textAlign: "center",
  cursor: "pointer",
  span: {
    fontSize: "13px",
    color: active ? "#FFFF00" : "#9B9B9B",
  },
}));
