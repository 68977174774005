import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { IconApp, Modal } from "../";
import SaveIcon from "@mui/icons-material/Save";

export const ModalAddUser = (props: { open: boolean; onClose: () => void }) => {
  return (
    <Modal
      {...props}
      name="Add User"
      description="View & edit your charge point’s pricing"
      icon={<IconApp width={"27px"} name={"IdLingPenalty"} />}
    >
      <Box display={"flex"} flexDirection="column" gap={2}>
        <Box display={"flex"} flexDirection="column" gap={1}>
          <Typography>Email address</Typography>
          <TextField fullWidth />
        </Box>
        <Box display={"flex"} flexDirection="column" gap={1}>
          <Typography>Phone number</Typography>
          <TextField fullWidth />
        </Box>
        <Box display={"flex"} flexDirection="column" gap={1}>
          <Typography>Charge Point Id</Typography>
          <TextField fullWidth />
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Button
              variant="contained"
              fullWidth
              size="large"
              startIcon={<SaveIcon fontSize="large" />}
            >
              Save Change
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button fullWidth size="large" variant="text">
              Reset
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};
