export const URL_API = {
  AUTH: {
    LOGIN: "v1/authenticate/login",
  },
  TRANSACTIONS: {
    GET_LIST: "/v1/transactions",
    GET_DETAIL: "/v1/transactions/",
  },
  CHARGE_SESSIONS: {
    GET_LIST: "/v1/charge-sessions",
    GET_DETAIL: "/v1/charge-sessions/",
    UPDATE: "",
    DELETE: "",
    GET_SETTLE_ENERGY: "/v1/charge-sessions/",
  },
  LOCATION: {
    GET_LIST: "/v1/locations",
    CREATE: "v1/locations",
    GET_DETAIL: "/v1/locations/",
    UPDATE: "/v1/locations/",
    DELETE: "/v1/locations/",
    ENABLE_SUBSCRIPTION_PLAN: "/v1/locations/",
  },
  PROMO_CODE: {
    GET_LIST: "/v1/promo-codes",
    GET_DETAIL: "/v1/promo-codes/",
    CREATE: "/v1/promo-codes/",
    UPDATE: "/v1/promo-codes/",
    DELETE: "/v1/promo-codes/",
    CALCULATE: "/v1/promo-codes/",
    INVALIDATE: "/v1/promo-codes/",
  },
  PROMO_PROGRAM: {
    GET_LIST: "/v1/promo-programs",
    GET_DETAIL: "/v1/promo-programs/",
    UPDATE: "/v1/promo-programs/",
    DELETE: "/v1/promo-programs/",
    CREATE: "/v1/promo-programs/",
  },
  SUBSCRIPTION_PLANS: {
    GET_LIST: "/v1/subscription-plans",
    GET_DETAIL: "/v1/subscription-plans/",
    CREATE: "/v1/subscription-plans",
    CREATE_SUBSCRIBE: "/v1/subscription-plans/subscribe",
    GENERATE_ACTIVATION_CODE:
      "/v1/subscription-plans/generate-activation-codes",
    UPDATE: "/v1/subscription-plans/",
    DELETE: "/v1/subscription-plans/",
  },
  SUBSCRIPTION: {
    GET_LIST: "/v1/subscriptions",
    ACTIVE: "/v1/subscriptions",
    CREATE_PAYMENT: "/v1/subscriptions",
    DE_ACTIVE: "/v1/subscriptions",
    DELETE: "/v1/subscriptions/",
  },
  USER: {
    CREATE: "v1/users/",
    GET_LIST: "/v1/users",
    GET_LIST_RESTRICTED: "/v1/users/restricted-users",
    UPDATE: "v1/users/",
  },
  CHARGE_POINTS: {
    GET_LIST: "/v1/charge-points",
    GET_DETAIL: "/v1/charge-points/",
    UPDATE: "",
    DELETE: "",
    GET_LIST_RESTRICTED: "/v1/evses/restricted-users",
    ENABLE_SUBSCRIPTION_PLAN: "/v1/charge-points/",
  },
  EVBS: {
    GET_LIST: "/v1/evses",
  },
  INFORMATION_CATEGORY: {
    GET_LIST: "/v1/information-categories",
    DELETE: "/v1/information-categories",
  },
  INFORMATION: {
    LIST: "/v1/information",
  },
  UPLOAD: "/v1/upload",
};
