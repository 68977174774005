import { Box } from "@mui/material";
import styled from "@emotion/styled";

export const Card = styled(Box)({
  borderRadius: "15px",
  background: "#1C1C1C",
  // boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  // boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  overflow: "hidden",
});
